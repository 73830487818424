@import url("./fonts.css");

:root {
  /* Fonts */
  --font-primary: Inter, system-ui;

  /* Colors */
  --color-bg-primary: #fff;
  --color-text-primary: #272727;
  --color-text-secondary: #404040;
  --color-text-landing: #1a1a1a;
  --color-text-secondary-profile: #9f9f9f;
  --color-landing-paragraph: rgba(0, 0, 0, 0.6);
  --color-border: #f3f3f3;
  --color-border-option: rgba(0, 0, 0, 0.1);
  --color-icon: #707171;
  --color-accent-dark: #222222;
  --color-dark-hover: #000;
  --color-text-white: #fff;
  --color-interraction: #f3f3f3;
  --color-lines: rgb(0 0 0 / 8%);
  --color-theme: #c61230;
  --color-checkbox: #e9e9e9;
  --color-text-grey: #6a6a6a;
  --color-text-button: #aaa;
  --color-profile-progress: #109263;
  --color-profile-text: #888;
  --color-new-product-legend: rgba(0, 0, 0, 0.3);
  --color-step: #d9d9d9;
  --color-order-green: #277c2d;
  --backdrop-color: rgba(0, 0, 0, 0.5);
  --color-button-checked: #f6f6f6;
  --color-tip-border: #ebebeb;
  --color-input-label: #474747;
  --color-description: #ebebeb;
  --color-landing-grey: #6a6a6a;
  --color-tip-frame: #790100;
  --color-tip-bg: rgba(121, 1, 0, 0.03);
  --color-footer: #faf9f9;
  --color-text-light-grey: #aaa;
  --color-boxes-border: #e6e6e6;
  --order-state-span-grey: #e0e0e0;
  --order-state-span-yellow: #e3ab1c;
  --order-state-span-blue: #265ac0;
  --order-state-span-green: #26c063;
  --order-state-span-red: #e02a2a;
  --color-filter-button: rgba(230, 230, 230, 1);
  --color-filter-button-checked: rgba(51, 51, 51, 1);
  --color-back-button: rgba(249, 249, 249, 1);
  --color-dashed-line: rgba(141, 141, 141, 1);
  --color-media-form-bg: rgba(250, 250, 250, 1);
  --color-empty-photo: rgba(242, 242, 242, 1);
  --color-text-price-old: rgba(111, 111, 111, 1);

  /* Sizes */
  --size-text-primary: 1rem;
  --size-text-secondary: 0.9rem;
  --size-text-extra-small: 10px;
  --size-text-small: 12px;
  --size-text-profile: 1rem;
  --size-text-title-modal: 1rem;
  --size-text-heading-l: 28px;
  --size-text-policy-heading: 25px;
  --size-text-landing-title: 30px;
  --size-text-landing-paragraph: 1rem;
  --size-text-heading-large: 32px;
  --size-text-profile-heading: 1.2rem;
  --size-text-profile-value: 16px;
  --size-text-profile-product: 20px;
  --size-radius-fields: 8px;
  --size-radius-button: 5px;
  --size-text-landing-heading: 50px;
  --size-text-order-title: 23px;
  --size-text-mob-paragraph: 1rem;
  --size-text-engagement: 27px;

  /* Indents */
  --space-primary: 1rem;
  --space-double: calc(var(--space-primary) * 2);
  --space-small: 5px;
  --space-gap: 10px;

  /* Transition */
  --time-function: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

* {
  box-sizing: border-box;
  font-family: var(--font-primary);
}

body,
ul,
li,
h1,
h2,
h3,
p,
fieldset,
input {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer !important;
  white-space: nowrap;
  border: none;
  outline: none;
  transition: background-color 300ms;
  background-color: #fff;

  user-select: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

button:focus,
button:active {
  outline: none; /* Ensure the outline is removed on focus and active states */
  box-shadow: none; /* Remove any box shadow that might appear on focus */
  -webkit-tap-highlight-color: transparent;
}

fieldset {
  border: 0;
}

fieldset:disabled * {
  opacity: initial;
  color: initial;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
 Containers
*/
.container-center {
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
p,
div,
span,
a,
button,
input,
label,
li {
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

@media screen and (min-width: 1200px) {
  .container-center {
    padding: 0 80px;
    width: 1200px;
  }
}

.container-catalog-header {
  width: 100%;
  padding: 20px;
}

@media screen and (min-width: 1024px) {
  .container-catalog-header {
    padding: 20px 30px;
  }
}

@media screen and (min-width: 1280px) {
  .container-catalog-header {
    width: 1280px;
  }
}

@media screen and (min-width: 1728px) {
  .container-catalog-header {
    width: 1468px;
    padding: 20px 0;
  }
}

.container-merchant-page {
  padding: 30px 22px;
}

@media screen and (min-width: 500px) {
  .container-merchant-page {
    padding: 30px 0;
    margin: 0 auto;
    width: 500px;
  }
}

.container-product-page {
  padding: 0 0 0 0;
}

@media screen and (min-width: 450px) {
  .container-product-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .container-product-page {
    padding: 0 40px 40px 40px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }
}

.container-landing {
  min-height: 100vh;
}

@media screen and (min-width: 1024px) {
  .container-landing {
    overflow: hidden;
  }
}

@media screen and (min-width: 1600px) {
  .container-landing {
    width: 1280px;
    margin: 0 auto;
  }
}

.container-relative {
  position: relative;
}

.container-text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 264px;
  text-align: center;
}

/* Flex styles */
.fx {
  display: flex;
}

.fx--col {
  flex-direction: column;
}

.fx-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fx--justify-center {
  justify-content: space-between;
}

.fx--ai-center {
  align-items: center;
}

/* End flex styles */

/* Text styles */
.text-primary {
  font: var(--font-primary);
  font-size: var(--size-text-primary);
  color: var(--color-text-primary);
}

.text-secondary {
  font: var(--font-primary);
  font-size: var(--size-text-secondary);
  color: var(--color-text-secondary);
}

.text-profile-heading {
  font: var(--font-primary);
  font-size: var(--size-text-profile-heading);
  color: var(--color-text-primary);
}

.text-profile-modal {
  font: var(--font-primary);
  font-size: var(--size-text-title-modal);
  color: var(--color-text-primary);
}

.text-profile-section {
  font: var(--font-primary);
  font-size: var(--size-text-profile-value);
  color: var(--color-text-primary);
}

.text-profile-product {
  font: var(--font-primary);
  color: var(--color-text-primary);
  font-size: var(--size-text-profile-product);
}

.text-profile-primary {
  font: var(--font-primary);
  font-size: var(--size-text-profile);
  color: var(--color-text-primary);
}

.text-order-page {
  font: var(--font-primary);
  font-size: var(--size-text-order-title);
  color: var(--color-text-primary);
}

.text-landing {
  font: var(--font-primary);
  font-size: var(--size-text-landing-title);
  color: var(--color-text-landing);
}

.text-policy-heading {
  font: var(--font-primary);
  font-size: var(--size-text-policy-heading);
  color: var(--color-text-landing);
}

.text-paragraph {
  font: var(--font-primary);
  color: var(--color-text-landing);
  font-size: var(--size-text-mob-paragraph);
}

.text-landing-heading {
  font: var(--font-primary);
  font-size: var(--size-text-heading-l);
  color: var(--color-text-landing);
}

@media screen and (min-width: 1024px) {
  .text-landing-heading {
    font-size: var(--size-text-landing-heading);
  }
}

.text-welcome-heading {
  font: var(--font-primary);
  font-size: var(--size-text-heading-l);
  color: var(--color-text-landing);
}

.text-small {
  font-weight: 300;
}

.form-heading {
  font-size: var(--size-text-heading-l);
  color: var(--color-text-primary);
}

.text-medium {
  font-weight: 400;
}

.text-large {
  font-weight: 500;
}

.text-bold {
  font-weight: 500;
}

.text-huge {
  font-weight: 500;
}

.text-line-through {
  color: var(--color-text-price-old);
  text-decoration: line-through;
}

/* End text styles */

.space-small {
  margin-top: var(--space-small);
}

/* Backdrop */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdrop-color);
  transition: opacity 250ms var(--time-function);
  z-index: 8;
}