@font-face {
  font-family: "HarmonyOS Sans";
  src: url('../../public/fonts/HarmonyOS_Sans_Regular.ttf') format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "HarmonyOS Sans";
  src: url('../../public/fonts/HarmonyOS_Sans_Medium.ttf') format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "HarmonyOS Sans";
  src: url('../../public/fonts/HarmonyOS_Sans_Bold.ttf') format("truetype");
  font-weight: 600;
}
